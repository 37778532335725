import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {AppReduxState} from "kbd/core/redux/app/AppReduxState";
import {
    CloseMeType,
    NextPageType,
    PrevPageType,
    SetBarBottomMetrics,
    SetBarBottomMetricsPayload,
    SetComplianceFlow,
    SetComplianceFlowPayload,
    SetCountryCode,
    SetCountryCodePayload,
    SetFormErrors,
    SetFormErrorsPayload,
    SetModal,
    SetModalPayload, SetPlatformType, SetPlatformTypePayload,
    SetProduct,
    SetProductPayload, SetTooltip, SetTooltipPayload,
    SetT2FaVerificationEnabled, SetBooleanPayload,
    SetShowCallMeButton,
    DoCallMeType,
    SetCallMeSubmitted,
    SetShowCallMeSubmittedMessage,
    SetDynamicConfiguration,
    SetDynamicConfigurationPayload, UpdatePlatformLanguageType,
} from "kbd/core/redux/app/AppReduxActions";
import AppEngine from "kbd/core/engine/AppEngine";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {InitChat, InitChatPayload, SetAppReadyType, SetBrandPropsType} from "platform/redux/core/CoreActions";

export default class AppReducer extends Reducer<AppReduxState> {

    private static _instance: AppReducer;

    public static instance(): AppReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const appEngine: AppEngine = Platform.engine(ServiceType.App);
        this._middlewareActions.set("@@router5/TRANSITION_START", appEngine.onChangeRoute);
        this._middlewareActions.set(SetAppReadyType, appEngine.onAppReady);
        this._middlewareActions.set(SetBrandPropsType, appEngine.doSetBrandProps);
        this._middlewareActions.set(LoadLanguageType, appEngine.onLoadLanguage);
        this._middlewareActions.set(CloseMeType, appEngine.closeMe);
        this._middlewareActions.set(NextPageType, appEngine.setNextPage);
        this._middlewareActions.set(PrevPageType, appEngine.setPrevPage);
        this._middlewareActions.set(DoCallMeType, appEngine.doCallMeRequest);
        this._middlewareActions.set(UpdatePlatformLanguageType, appEngine.doUpdateLanguage);
    }

    public get name(): string {
        return "kbdApp";
    }

    protected setup(builder: ReducerBuilder<AppReduxState>): void {
        builder
            .init({
                modals: new TSMap(),
                errors: new TSMap(),
                barBottomLeft: 0,
                barBottomHeight: 0,
                T2FaVerificationEnabled: true
            })
            .handle(SetComplianceFlow, (state: AppReduxState, {payload}: Action<SetComplianceFlowPayload>) => {
                return Object.assign({}, state, {
                    complianceFlowType: payload.complianceFlowType
                });
            })
            .handle(SetPlatformType, (state: AppReduxState, {payload}: Action<SetPlatformTypePayload>) => {
                return Object.assign({}, state, {
                    platformType: payload.platformType
                });
            })
            .handle(SetProduct, (state: AppReduxState, {payload}: Action<SetProductPayload>) => {
                return Object.assign({}, state, {
                    product: payload.product
                });
            })
            .handle(InitChat, (state: AppReduxState, action: Action<InitChatPayload>) => {
                return Object.assign({}, state, {
                    zeChatAvailable: true
                });
            })
            .handle(SetTooltip, (state: AppReduxState, {payload}: Action<SetTooltipPayload>) => {
                return Object.assign({}, state, {
                    tooltip: payload.tooltip
                });
            })
            .handle(SetModal, (state: AppReduxState, {payload}: Action<SetModalPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.modals.set(payload.modalType, {
                    visible: payload.visible,
                    parameters: payload.parameters
                });
                return newState;
            })
            .handle(SetCountryCode, (state: AppReduxState, {payload}: Action<SetCountryCodePayload>) => {
                this._logger.debug("Set country code to: " + payload.countryCode);
                return Object.assign({}, state, {
                    countryCode: payload.countryCode
                });
            })
            .handle(SetFormErrors, (state: AppReduxState, {payload}: Action<SetFormErrorsPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.errors = payload.errors ? payload.errors : new TSMap();
                return newState;
            })
            .handle(SetBarBottomMetrics, (state: AppReduxState, {payload}: Action<SetBarBottomMetricsPayload>) => {
                this._logger.debug("Set bar bottom height: " + payload.height + " left: " + payload.left);
                return Object.assign({}, state, {
                    barBottomLeft: Utils.isNotNull(payload.left) ? payload.left : state.barBottomLeft,
                    barBottomHeight: Utils.isNotNull(payload.height) ? payload.height : state.barBottomHeight
                });
            })
            .handle(SetT2FaVerificationEnabled, (state: AppReduxState, {payload}: Action<SetBooleanPayload>) => {
                return Object.assign({}, state, {
                    T2FaVerificationEnabled: payload.value
                });
            })
            .handle(SetShowCallMeButton, (state: AppReduxState, {payload}: Action<SetBooleanPayload>) => {
                return Object.assign({}, state, {
                    ShowCallMeButton: payload.value
                });
            })
            .handle(SetCallMeSubmitted, (state: AppReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    CallMeSubmitted: true
                });
            })
            .handle(SetShowCallMeSubmittedMessage, (state: AppReduxState, {payload}: Action<SetBooleanPayload>) => {
                if (payload.value) {
                    setTimeout(() => Platform.dispatch(SetShowCallMeSubmittedMessage({value: false})), 5000);
                }
                return Object.assign({}, state, {
                    ShowCallMeSubmittedMessage: payload.value
                });
            })
            .handle(SetDynamicConfiguration, (state: AppReduxState, {payload}: Action<SetDynamicConfigurationPayload>) => {
                return Object.assign({}, state, {
                    dynamicConfiguration: payload.configuration,
                });
            });
    }
}
